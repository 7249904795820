import React from "react";
import { BrowserRouter, Routes, Route, Outlet, Link } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Container from "react-bootstrap/Container";
import { ReactComponent as LogoHorizontal } from "./logoHorizontal.svg";
import Privacy from "./pages/privacy";
import Home from "./pages/home";
import Terms from "./pages/terms";

function App() {
  return (
    <>
      <Navbar expand="lg" className={"navbarStyle sticky-top"}>
        <Container>
          <Navbar.Brand href="/">
            <LogoHorizontal />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ms-auto">
              <Nav.Link href="/#theapp">The app</Nav.Link>
              <Nav.Link href="/#about">About us</Nav.Link>
              <Nav.Link href="/#contacts">Contacts</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <BrowserRouter>
        <Routes>
          <Route path="/" exact element={<Home />} />
          <Route path="privacy"  element={<Privacy />} />
          <Route path="termsandconditions"  element={<Terms />} />
        </Routes>
      </BrowserRouter>
      <Outlet />
    </>
  );
}

export default App;
