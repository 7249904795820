import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import emailIcon from "../env2.svg";
import pinIcon from "../pin.svg";
import instaIcon from "../insta.svg";
import logoFooterIcon from "../logoFooter.svg";
import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <Container>
      <Row>
        <Col sm={1}></Col>
        <Col sm={10}>
          <Container>
            <Row>
              <Col sm={12}>
                <Row className={"align-items-center footerStyle"}>
                  <p className={"contactsLettering py-3"}>
                    <img width={"22px"} height={"auto"} src={instaIcon} />{" "}
                    @outwireapp
                  </p>
                  <p className={"contactsLettering py-3"}>
                    <img width={"22px"} height={"auto"} src={emailIcon} />{" "}
                    outwire@outwire.app
                  </p>
                  <p className={"contactsLettering py-3"}>
                    <img width={"22px"} height={"auto"} src={pinIcon} />
                    Lisbon, Portugal
                  </p>
                </Row>
                <Row>
                  <ul class="list-group list-group-horizontal justify-content-center">
                    <li class="list-group-item">
                      <Link to="privacy">Privacy</Link>
                    </li>
                    <li class="list-group-item">
                      <Link to="termsandconditions">Terms and Conditions</Link>
                    </li>
                  </ul>
                </Row>
              </Col>
            </Row>
          </Container>
        </Col>
        <Col sm={1}></Col>
      </Row>
    </Container>
  );
}
