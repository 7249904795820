import React from 'react';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";


export default function SharedWidget({title, image,direction, subtitle, imageSize}) {

    var  directionContent = direction=='LTR' ? 'flex-row' : 'flex-row-reverse'; 
    var  directionText = direction=='LTR' ? 'text-end' : 'text-start'; 

    return (
        <Container className={'py-5'}>
      <Row>
        <Col sm={1}></Col>
        <Col sm={10}>
          <Container>
            <Row className={"align-items-center " +  directionContent}>
              <Col sm={4}>
                <h1 className={"mediumTitle " + directionText}>
                  {title}
                </h1>
                <p className={directionText}>{subtitle}</p>
                
              </Col>
              <Col sm={8}>
              <div className={'text-center'}>
                <img width={imageSize} src={image} />
                </div>
              </Col>
            </Row>
          </Container>
        </Col>
        <Col sm={1}></Col>
      </Row>
    </Container>
    )
}
