import React from "react";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import creatEventImage from "../createEvent.png";
import discoverThingsImage from "../discoverThings.png";
import shareExperiencesImage from "../shareExperiences.png";
import ticektingImage from "../ticketingImage.png";
// import miguelImage from "../miguel.png";
// import noraimage from "../nora.jpeg";
// import carlosImage from "../carlos.jpeg";
// import ricardoImage from "../ricardo.jpeg";
// import gilImage from "../gil.jpeg";
// import rodrigoImage from "../rodrigo.png";
import allScreensImage from "../allScreens.png";
import SetionOutwire from "../widgets/markting";
import SharedWidget from "../widgets/sharedWidget";
//import TeamWidget from "../widgets/teamWidget";
import Footer from "../widgets/footer";
import TicketingWidget from "../widgets/ticketingWidget";
const home = () => {
  // var allTeam = [
  //   { name: "Miguel Pinto", job: "co-founder", image: miguelImage },
  //   { name: "Rodrigo Pina", job: "co-founder", image: rodrigoImage },

  //   { name: "Carlos Almeida", job: "Developer", image: carlosImage },
  //   { name: "Gil Nunes", job: "UX/UI Designer", image: gilImage },
  //   { name: "Joao Nora", job: "Developer", image: noraimage },
  // ];
  return (
    <>
      <Container fluid className={"containerBg"} id={"home"}>
        <Row>
          <div className={"py-5"}></div>
          <SetionOutwire
            title={"A wire connecting people to new experiences!"}
          />
        </Row>
        <Row>
          {" "}
          <h1 className={"bigTitleGrey"} id={"theapp"}>
            The App
          </h1>
        </Row>
        <Row>
          <div className={"py-3"}></div>
          <SharedWidget
            image={creatEventImage}
            direction={"RTL"}
            title={"Create events"}
            imageSize={"60%"}
            subtitle={
              "Create public, private and invite-only events. No more endless group chats to arrange activities with friends."
            }
          />
        </Row>
        <Row>
          <div className={"py-3"}></div>
          <SharedWidget
            image={discoverThingsImage}
            direction={"LTR"}
            title={"Discover things around you"}
            imageSize={"60%"}
            subtitle={
              "So many things to do in your surroundings! From activities with your neighbors to the coolest bar in town. Discover new experiences and activities related to your interests."
            }
          />
        </Row>
        <Row>
          <div className={"py-3"}></div>
          <SharedWidget
            image={shareExperiencesImage}
            direction={"RTL"}
            title={"Share your experiences"}
            imageSize={"45%"}
            subtitle={
              "“Happiness is only real when shared” – And we want you to be happy! Share photos and videos of your events and see what kinds of things your friends are up to!"
            }
          />
        </Row>
        <Row>
          <div className={"py-3"}></div>
          <TicketingWidget
            image={ticektingImage}
            direction={"LTR"}
            title={
              "Revolutionize your events with secure, verifiable ticketing"
            }
            imageSize={"65%"}
            subtitle={
              "We leverage blockchain technology to equip event creators with secure and transparent ticketing and also protect them against scalping."
            }
          />
          <div className={"text-center imgAllScreens"}>
            <img src={allScreensImage} alt="imgs app" />
          </div>
        </Row>
        <Row>
          <h1 className={"bigTitleGrey pb-4"} id={"about"}>
            About us
          </h1>
        </Row>
        <Row>
          <Col className={"aboutUs"}>
            <Row>
              <Col></Col>
              <Col sm={12} md={6}>
                <p className={"whiteParagraph text-center"}>
                  Outwire is a Social Network & Event Management App born from
                  the need for new experiences, with new people, in new places!
                </p>
              </Col>
              <Col></Col>
            </Row>
          </Col>
        </Row>
        {/* <Row>
          <div className={"py-3"}></div>
          <TeamWidget
            team={allTeam}
            title={"Finding inspiration in every turn"}
            subtitle={
              "In 2021, Miguel Pinto and Rodrigo Pina started to imagine the Outwire app in order to build the bridge between Social Networking and Real Personal Connections. Currently we are five like-minded people that aim to make social networking go beyond the screen."
            }
          />
        </Row> */}
        <Row>
          <div className={"py-3"} id={"contacts"}></div>
          <h1 className={"bigTitleGrey"}>Contact us</h1>
          <div className={"py-3"}></div>
          <Footer />
        </Row>
      </Container>
    </>
  );
};

export default home;
