import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import "../App.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

export default function Privacy() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <Container fluid className={"containerBg privacy"}>
      <Row className={"my-5 align-items-end"}>
        <Col sm={1}></Col>
        <Col sm={10} md={10}>
          <Container>
            <h3>Privacy</h3>
            <br />
            <p>
              <br />
              &nbsp;
            </p>
            <p>
              We are committed to protecting the privacy of the personal
              information you provide to us when using Outwire and we value your
              privacy. That's why we have created this document (the
              &ldquo;Privacy Policy&rdquo;).
            </p>
            <p>
              It applies to all Users of the Platform. We invite you to
              carefully read our Privacy Policy and to contact us for any
              question about the collection and processing of your Personal Data
              at the following e-mail address: outwire@outwire.pt. Please note
              that the Privacy Policy is an integral part of the General Terms
              of Use and that the definitions set forth therein are applicable
              to this document.
            </p>
            <p>
              <strong>1. Scope and Application -</strong>The Privacy Policy
              details the Personal Data collected and processed when using our
              Application, the purposes for which Users' Personal Data is
              processed, how Ourwire uses it, and the features offered to allow
              Users to access and update their Personal Data. Personal Data is
              defined as any information relating to a natural person who can be
              identified, directly or indirectly. It is for example: the name,
              first name, email and postal address of a physical person, his
              image on a photograph or a video, an IP address, a location data,
              etc.
            </p>
            <p>
              <strong>
                2. Acceptance and Modification of Our PrivacyPolicy
              </strong>
              - By accepting the Terms of Use, you confirm that you have read
              and accept without limitation or qualification this Privacy
              Policy, which forms an integral part of the Terms of Use. In case
              of disagreement with one or more of its conditions, the User is
              free not to use or no longer to use our services (especially in
              case of modification of the terms of the Privacy Policy). This
              Privacy Policy may change from time to time, but any reduction in
              your rights will not be enforced without your consent. Outwire
              will post new versions of the Privacy Policy on the Application
              and will alert Users by means of a pop-up window that will appear
              upon opening the Application. In general, the Privacy Policy is
              always easily accessible from your User Account settings.
            </p>
            <p>
              <strong>3. Identification of the Data Controller</strong>- The
              processing of your Personal Data is subject to Portuguese law as
              well as the EU Regulation 2016/679 of April 27, 2016, known as the
              General Data Protection Regulation (GDPR). According to the
              regulations in force, Outwire is the Controller of the processing
              of your Personal Data. In this capacity, Outwire undertakes to
              comply with the legal provisions in force and to carry out a
              lawful, fair and transparent processing of Personal Data of Users.
              Outwire also guarantees to the Users that this processing is in
              accordance with the explicit and legitimate purposes determined in
              the Privacy Policy. The duration of processing does not exceed the
              time necessary for the purposes for which the Personal Data are
              collected and processed.
            </p>
            <p>
              <strong>
                4. Categories of Personal Data Processed When Using the
                Application
              </strong>
            </p>
            <p>
              a) <em>Which Personal Data Are Processed</em>? In order to provide
              access to the Platform to its Services, Outwire may collect
              several categories of Personal Data about you, for different
              purposes, legal bases for processing and different retention
              periods. We offer a summary of all this information in the table
              below. If you wish to know more about the Data collected and the
              purposes of processing you can also read the details below the
              following table.
            </p>
            <table>
              <tbody>
                <tr>
                  <td>
                    <p>Purpose of the Processing</p>
                  </td>
                  <td>
                    <p>Personal Data Collected</p>
                  </td>
                  <td>
                    <p>Legal Ground for the Processing</p>
                  </td>
                  <td>
                    <p>Retention Period</p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>
                      Account creation and user identification &ndash; Account
                      Management
                    </p>
                  </td>
                  <td>
                    <p>
                      Mandatory Profile Data (name, username, birth date,
                      category interests, password, email)
                    </p>
                  </td>
                  <td>
                    <p>Performance of the contract between You and Outwire</p>
                  </td>
                  <td>
                    <p>
                      Until after 3 years of the last connection to the service
                      or until the request for removal
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>Purchase of paid features</p>
                  </td>
                  <td>
                    <p>Transaction data submitted by the story</p>
                  </td>
                  <td>
                    <p>Performance of the contract between You and Outwire</p>
                  </td>
                  <td>
                    <p>
                      Duration necessary for the execution of the transaction
                      increased by the legal retention period for accounting
                      purposes
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>Proper functioning of the platform and its services</p>
                  </td>
                  <td>
                    <p>
                      Data related to your activity on Outwire, Communications
                      with Outwire, Data related to devices and equipment used
                    </p>
                  </td>
                  <td>
                    <p>Legitimate interests of the Data Controller</p>
                  </td>
                  <td>
                    <p>2 years from collection date</p>
                  </td>
                </tr>
                <tr>
                  <td>&nbsp;</td>
                  <td>
                    <p>
                      Data sent by your phone&rsquo;s camera and photos stored
                      on your phone, Geolocation Data
                    </p>
                  </td>
                  <td>
                    <p>Consent</p>
                  </td>
                  <td>
                    <p>
                      Until after 3 years of the last connection to the service
                      or until the withdrawal of the consent
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p>Moderation of content with accordance with the law</p>
                  </td>
                  <td>
                    <p>Content Data, Data about your activity on Outwire</p>
                  </td>
                  <td>
                    <p>Compliance with our legal obligations</p>
                  </td>
                  <td>
                    <p>1 year from collection for metadata</p>
                    <p>5 years for data retained for dispute purposes</p>
                  </td>
                </tr>
              </tbody>
            </table>
            <p>
              <br />
              &nbsp;
            </p>
            <p>
              <strong>
                <u>Details On The Personal Data Collected and Processed</u>
              </strong>
            </p>
            <p>Outwire collects two different types of Data:</p>
            <p>(i) Data that you provide to us</p>
            <p>
              Profile Data: This is the Data that you are required to enter when
              you sign in: your email address, name and username, gender, date
              of birth, interests, and password.
            </p>
            <p>
              Communications with Outwire : When you report a problem with the
              Platform (inappropriate content, bugs, errors), respond to a
              survey about the quality of our Services, request assistance, or
              make a request to exercise your rights, we record the information
              you agree to share with us or that we need to respond to your
              request.
            </p>
            <p>
              (ii) Data we collect when you use our Services Data related to
              your activity on Outwire:
            </p>
            <p>
              This notably includes: Connection Data - IP address, date of
              registration, date of your last connection; Data about how you
              interact with other Users: the number of followers, comments and
              likes left on Feed shared by your friends, the users with whom you
              interact the most; Data about how you use our Services including
              the time of event posting, number of Outwire posts, etc.
            </p>
            <p>
              <strong>Content Data:&nbsp;</strong>We collect content that you
              create through our Services such as event details (title,
              location, category, type of event, messaging history, beginning
              and ending time, number of participants) and comments and content
              added to the Feed. Note that Users who view your content can
              always take a copy or screenshot of it. Therefore, we strongly
              recommend that you do not post any content that you do not want to
              share.
            </p>
            <p>
              <strong>Geolocation Data</strong>: When searching for events, the
              Platform offers you the possibility to use your exact location.
              Use of the Service requires your consent to the collection of
              Geolocation Data. If you do not want Outwire to collect your
              Geolocation Data, you may refuse to give the Platform permission
              to access your location when you first open the application. If
              you wish to withdraw your consent after accepting such processing,
              you must change the permissions given to the application in the
              system settings of your phone.
            </p>
            <p>
              <strong>Phonebook Data:</strong>With your consent, Outwire may
              access your phone book to identify your contacts already
              registered on Outwire or so that you can invite your friends to
              register on the Platform. We never access your contact list
              without your permission.
            </p>
            <p>
              <strong>Device and Equipment Data</strong>: We collect information
              about and from the devices and equipment you use to access
              Outwire. This includes your IP address, device type, application
              crashes, operating system version, language used.
            </p>
            <p>
              <strong>Cameras and Photos:&nbsp;</strong>Most of our Services
              require that you allow us to collect Data from your phone's camera
              and photo library (i.e. send and upload photos). If you refuse to
              allow us access to your camera or photo library, you may receive
              photos from and contact other Users, but you will not be able to
              take, upload and share your own photos.
            </p>
            <p>
              <strong>
                <u>Details of the legal basis and purposes of the processing</u>
              </strong>
            </p>
            <p>
              We process the above-described Data in accordance with the
              requirements of the GDPR for various purposes on the bases of:
            </p>
            <p>
              <strong>Contract performance&nbsp;</strong>where the processing
              enables Outwire to provide the Services as described in the Terms
              of Service. This includes:
            </p>
            <ul type="disc">
              <li>
                Access to the Platform and related services (profile visibility,
                transmission of comments and shared content, suggestion of
                friends);
              </li>
              <li>The proper functioning of Users' accounts;</li>
              <li>
                Notifications regarding new messages or new events created by
                other users;
              </li>
              <li>Notification of changes to our services;</li>
              <li>User support services;</li>
              <li>
                Administration of the Platform, repair and correction of bugs,
                updates;
              </li>
              <li>
                The provision of services for sharing User Content and
                interacting with other Users;
              </li>
            </ul>
            <p>
              Our <strong>legitimate interests&nbsp;</strong>where the
              processing allows us to provide you with an efficient and dynamic
              Platform. This includes:
            </p>
            <ul type="disc">
              <li>
                Monitoring User Content, comments, messages and associated
                metadata for administrative purposes and to verify their
                compliance with our Terms of Service;
              </li>
              <li>
                Verifying the compatibility of the content presentation methods
                with respect to the type of device used;
              </li>
              <li>
                The understanding of the modalities of use of the Platform by
                the Users for the purposes of improvement, development and
                commercial promotion of the Application;
              </li>
              <li>Verifying the stability and security of the Platform;</li>
              <li>
                Identifying criminal activities, fraudulent behavior, misuse of
                the Platform;
              </li>
              <li>
                Sending surveys and requesting feedback in order to improve the
                Services offered.
              </li>
            </ul>
            <p>
              Compliance with our <strong>legal obligations</strong> where the
              processing of Data is aimed at preventing and combating abusive,
              fraudulent, and illegal activities.
            </p>
            <p>
              Your consent where the processing relates to geolocation or
              phonebook Data. This includes:
            </p>
            <ul type="disc">
              <li>
                Geolocation for the purpose of recommending the User with events
                which are geographically close to the User's location;
              </li>
              <li>
                Geolocation for the purpose of indicating where User content was
                created;
              </li>
              <li>
                Access to the phone book to identify Users of our Services that
                you know.
              </li>
            </ul>
            <p>b) Cookies</p>
            <p>
              A &ldquo;cookie&rdquo; (or &ldquo;tracker&rdquo;) is a connection
              indicator that designates a text file that may be recorded in a
              dedicated space on the hard disk of your terminal (computer,
              tablet, or smartphone), when you consult a service available
              online. A cookie allows its issuer to identify the terminal in
              which it is stored, during the period of validity or storage of
              the cookie. The cookie does not identify the User as such; it is
              used to record your browsing information on the Platform. When you
              connect to the Platform, information relating to your browsing is
              likely to be recorded in &ldquo;Cookies&rdquo; files installed on
              your terminal, according to the choices you may have expressed
              concerning cookies and which can be modified at any time. These
              cookies allow personalized navigation and are also used for
              analytical purposes (audience measurement). The existence of
              cookies and their purpose(s) will be indicated to you when you
              connect to the Platform, by the presence of an information banner
              placed at the bottom or top of the home page. The deposit and
              reading of cookies on your terminal generally requires your prior
              consent, which, if applicable, is obtained by clicking on &ldquo;I
              accept&rdquo;. The consent given for the use of cookies is valid
              for 13 months. After this period we will ask you to renew your
              agreement. In case of refusal of cookies, objection to those
              stored by default, or deletion of the cookies, the User is
              informed that he can no longer benefit from a number of features
              that are nevertheless necessary to navigate in some areas of the
              Platform. Where appropriate, Outwire disclaims any responsibility
              for the consequences related to the degraded operation of its
              Services resulting from the inability of Outwire to record or view
              the cookies necessary for the proper functioning of the Platform
              and that you have refused or deleted. Finally, it is important to
              distinguish the cookies issued on the Platform from those issued
              by third parties. In this respect, we inform you that cookies may
              be placed from time to time on certain pages of the Platform by
              third parties (advertisers or others). Please be aware that
              Outwire has no control over the use of cookies by third parties.
            </p>
            <p>
              <u>Outwire uses the following cookies and trackers:</u>
            </p>
            <p>
              <strong>On the Application:</strong>
            </p>
            <ul type="disc">
              <li>
                Cookies that are necessary for you to use the Services, such as
                allowing you to stay logged into your account;
              </li>
              <li>
                Cookies analyzing your use of the Application to generate
                statistical reports, without identifying you:
              </li>
            </ul>
            <p>o Google Analytics</p>
            <ul type="disc">
              <li>
                Cookies necessary for the proper and efficient operation of the
                Application, for example by saving your user preferences.
              </li>
              <li>
                On the website www.outwire.app: cookies analyzing your use of
                the website to generate statistical reports, without identifying
                you:
              </li>
            </ul>
            <p>o Google Analytics</p>
            <p>
              <strong>5. Conditions of use of Personal Data</strong>
            </p>
            <p>
              Any use of Personal Data for purposes other than those set out
              above in our Privacy Policy will require the prior express consent
              of the User. Outwire undertakes to keep the Personal Data of the
              Users only for as long as is strictly necessary for the declared
              processing of the data for the above-mentioned purposes or for the
              exercise and establishment of its legal rights, and in any case
              within the limits imposed by the law. Outwire undertakes to delete
              the Personal Data from its databases at the end of these various
              periods. In case of request for deletion of your account, it will
              be effective within 30 (thirty) days. All of our backups are
              automatically deleted after 90 (ninety) days. However, Outwire may
              retain certain information for a period of time after the closure
              of the User's personal account in order to meet legal, accounting
              and tax obligations and, in particular, to prevent possible
              unlawful behavior after the deletion of a User Account (reuse of
              an old account by a third party). In this case, the Personal Data
              will be deactivated and will no longer be accessible online.
            </p>
            <p>
              <strong>6. Sharing of Information with Third Parties</strong>
            </p>
            <p>
              a) We may receive Personal Data about you from various third
              parties as set out below:
            </p>
            <ul type="disc">
              <li>
                if you log into the Services through a third-party account such
                as Facebook or Google, we will collect certain information
                relating to your account with those third parties (including
                your name, profile image, age group, gender and other
                information available on your public profile), your date of
                birth and your e-mail address. You can manage the information
                data which is shared by such third parties with us by amending
                your preferences through using the privacy settings which these
                third parties provide on their platforms;
              </li>
              <li>
                technical data from the following parties:
                <ul type="circle">
                  <li>
                    analytics providers based inside and outside the EU, such as
                    Adobe Analytics and Google Analytics;
                  </li>
                  <li>
                    advertising networks based inside and outside the EU, such
                    as Facebook Audience Network and FreeWheel; and
                  </li>
                  <li>
                    search information providers, based in the EU, such as Adobe
                    Audience Manager.
                  </li>
                </ul>
              </li>
            </ul>
            <p>
              For further information about how each of these parties collect
              and process your data, please see the 'Third-Party advertising
              companies section below;
            </p>
            <ul type="disc">
              <li>
                contact, financial and transaction data from providers of
                technical, payment and delivery services (e.g. your bank, our
                payment processing partners and credit reporting agencies);
              </li>
              <li>
                identity and contact data from data brokers, aggregators and
                publicly availably sources; and
              </li>
              <li>
                third parties that provide certain services to us within the
                Services, for example, facilitating and administering user
                feedback forms, newsletter subscriptions and competitions
              </li>
            </ul>
            <p>b) Third-party marketing</p>
            <p>
              We will get your express opt-in consent before we share your
              Personal Data with any company outside our group of companies for
              marketing purposes.
            </p>
            <p>
              <strong>Opting out</strong>
            </p>
            <p>
              We want to provide you with choices regarding certain Personal
              Data uses, particularly around marketing and advertising
              (including the sending of marketing messages). You can change your
              choices at any time by logging into the Websites or the Apps and
              checking or unchecking relevant boxes to adjust your marketing
              preferences. For such purpose, you can contact us at any time by
              emailing&nbsp;
              <a href="mailto:support@outwire.app">support@outwire.app</a>.
            </p>
            <p>
              Where you opt out of receiving these marketing messages, this will
              not apply to Personal Data provided to us as a result of the
              Services. Therefore, you will continue to receive essential
              messages about the functionality and/or administration of our
              Services (for example, as mentioned above, where we need to send
              you a password reminder, to notify you about Services updates or
              amendments to legal terms).
            </p>
            <p>c) Third-party advertising companies</p>
            <p>
              We want to make sure that our advertising and marketing is
              relevant and interesting to you and our other users. To achieve
              this, we use third-party advertising and technology companies to
              act as data processors and serve ads and/or provide aggregated
              data to assist in serving ads when you visit or use our Services.
              This includes third party technology companies which collect data
              about you in order to build a profile of your preferences based on
              your activities when you visit or use our Services. We also use
              these companies to automatically collect data from you when you
              use our Services in order to help us identify the ads that are
              served to you and what you do after seeing those ads. In addition,
              we also share data with providers of web analytics tools, such as
              Google Analytics, to which we use to analyze your use of the
              Services.
            </p>
            <p>
              These third-party advertising companies collect, store and use
              data by integrating cookies and other tracking software on our
              Services. Please see our Cookie Policy section for further
              information
            </p>
            <p>
              In some cases, these third parties will also use the data that
              they collect for their own purposes, for example they may:
              aggregate your data with other data they hold and use this to
              inform advertising related services provided to other clients; and
              work with other advertising companies and share your Personal Data
              with such companies as part of the process.
            </p>
            <p>
              Please see our Cookie Policy section for more information on the
              third party advertising companies which collect, use and store
              data about you, and the use of cookies and other tracking
              technologies on our Services. From our Cookie Policy you can also
              find out how to exercise control over this collection and use of
              data. If you choose to restrict or prevent tracking for these
              purposes then the advertising you see when you visit or use our
              Services will no longer be tailored to your preferences.
            </p>
            <p>
              We might also share your data with social media or other similar
              platforms, so that you and other people can see relevant content
              on that platform. For example, we may use the Facebook Custom
              Audiences service and share your email address in a protected
              format with Facebook so that we can: include you in a custom
              audience that we will serve relevant advertising content to on
              Facebook; or create an audience of other Facebook users based on
              the information in your Facebook profile.
            </p>
            <p>
              d) Communication of Personal Data for external processing purposes
            </p>
            <p>
              Your information and Personal Data may, where appropriate, be
              transmitted to third party contractors involved in the provision
              of the Services (technical and hosting providers, sending
              notifications, publishing and sharing content, user tracking and
              satisfaction surveys, management of security incidents or
              fraudulent activity, etc.). Outwire undertakes to communicate
              Users' Personal Data only to authorized and trusted service
              providers, who process it on our behalf, according to our
              instructions, in accordance with this Privacy Policy and in
              compliance with any other appropriate security and confidentiality
              measures.
            </p>
            <p>
              <strong>
                e) Disclosure of Personal Data outside the European Union
              </strong>
            </p>
            <p>
              Processing Personal Data as described in the Privacy Policy may
              involve transferring your Data to one or more other countries,
              inside or outside the European Economic Area, including the United
              States. Where applicable, we use standard contractual clauses
              and/or other mechanisms approved by the European Commission to
              maintain a level of protection for your Personal Data equivalent
              to that guaranteed by the GDPR.
            </p>
            <p>
              <strong>f) Disclosure for Legal and Statutory Purposes</strong>
            </p>
            <p>
              Information and Personal Data may also be disclosed to a third
              party if Outwire is required to do so by law, regulation, or court
              order, or if such disclosure is necessary for the purposes of an
              investigation, court order or legal proceeding, either
              domestically or abroad. In addition, Outwire may share information
              and Personal Data with third party companies, consultants or
              individuals in order to: Enforce the Privacy Policy and the
              General Terms of Use of the Application in force, including to
              ascertain any breach thereof; Protect against any infringement of
              the rights, property or safety of its users, in accordance with
              and in compliance with the law.
            </p>
            <p>
              <strong>7. Security of Personal Data</strong>
            </p>
            <p>
              We implement technical and organizational measures, such as
              encryption of Data or restriction of access to authorized
              personnel, to ensure that your Personal Data is processed securely
              in accordance with the Privacy Policy. However, the transmission
              of information via the Internet involves irreducible risks that do
              not allow us to guarantee perfect security of the information
              transmitted via the Platform. Consequently, any transmission of
              Personal Data is made at your own risk.
            </p>
            <p>
              <strong>8. Respect of the rights of the Platform Users</strong>
            </p>
            <p>
              Users have certain rights regarding their Personal Data, as
              follows:
            </p>
            <p>
              8.1 - Right of access: it allows you to access free of charge the
              Personal Data collected about you.
            </p>
            <p>
              8.2 - Right of rectification: it allows you to update or correct
              the accuracy of your Data. You can exercise this right directly
              from your account settings.
            </p>
            <p>
              8.3 - Right of deletion: it can be exercised when the processing
              of Personal Data is no longer necessary for the purposes for which
              they were collected or, if applicable, when you withdraw your
              consent to the processing.
            </p>
            <p>
              8.4 Right to object to the collection and processing of all or
              part of your Personal Data: this right is not absolute and applies
              only to Personal Data collected for commercial prospecting
              purposes, including profiling when it is linked to commercial
              prospecting activities. When you choose to object to such
              processing we ask you to indicate the reasons for your objection
              so that we can balance our legitimate interests against your
              request.
            </p>
            <p>
              8.5 Right to limit the processing of Personal Data. This right is
              not absolute, and can only be applied when:
            </p>
            <ul type="disc">
              <li>
                a request for rectification has been made under the right of
                rectification described in 8.2. You may then request that the
                processing of your Personal Data be stopped for the time
                necessary to verify the accuracy of the requested rectification.
              </li>
              <li>
                you wish to object to the deletion of your Personal Data whose
                processing was unlawful;
              </li>
              <li>
                Outwire no longer needs the Personal Data in accordance with the
                purposes of processing but they are still necessary for the
                establishment, exercise or defense of legal claims;
              </li>
              <li>
                You wish to exercise your right to object as described in 8.4.
                You may then request that the processing of your Personal Data
                be stopped for the time necessary to balance our legitimate
                interests against your request.
              </li>
            </ul>
            <p>
              Before we can respond to a request to exercise one or more of your
              rights, we may ask you to confirm some of your Account information
              to verify your identity. In case of reasonable doubt, we may ask
              you for identification.
            </p>
            <p>
              <strong>9. Information Regarding Children</strong>
            </p>
            <p>
              Outwire is not intended for children under the age of 18. If you
              believe that we have Personal Information about a child or that it
              has been collected from a child under the required age, you may
              report your concerns to us by reporting the content or the User
              Account.
              <br />{" "}
              <strong>
                <br />
                &nbsp;10. Information Regarding Ticket Sales
              </strong>
              &nbsp;
              <br />
              &nbsp;
              <br /> <strong>a) Purchasing Tickets</strong>: Users have the
              option to purchase tickets to events within the Outwire
              application. When a user initiates a ticket purchase, Outwire
              collects transaction data such as payment information and billing
              details necessary to process the transaction. This data is
              collected and processed in accordance with the performance of the
              contract between the user and Outwire. The duration of processing
              for transaction data will extend for the duration necessary for
              the execution of the transaction, increased by the legal retention
              period for accounting purposes.
            </p>
            <p>
              <strong>b) Creation of Tickets:</strong>&nbsp;Additionally, users
              have the capability to create their own tickets for events through
              the Outwire platform. When a user creates a ticket, Outwire
              collects relevant information about the event, as well as its VAT
              and minimum age for the sales of tickets. This information is
              considered Content Data and is processed in compliance with
              Outwire's legitimate interests to provide efficient and dynamic
              services to users.
            </p>
            <p>
              <strong>c) Handling of Ticket Data:&nbsp;</strong>Outwire ensures
              the security and confidentiality of ticket data processed within
              the application. Technical and organizational measures are
              implemented to protect the integrity and privacy of ticket-related
              information. However, users should be aware that the transmission
              of ticket data via the Internet carries inherent risks, and users
              engage in such transactions at their own risk.
            </p>
            <p>
              <strong>d) Retention of Ticket Data:</strong>&nbsp;Ticket data
              collected and processed by Outwire will be retained for the
              duration necessary to fulfill the purposes for which it was
              collected, as outlined in this Privacy Policy. Upon completion of
              the ticket transaction or event, Outwire may retain certain
              information for a period of time to meet legal, accounting, and
              tax obligations, as well as to prevent potential unlawful
              activities.
            </p>
            <p>
              <strong>e) Third-Party Service Providers:</strong>&nbsp;To
              facilitate ticket sales and event management, Outwire may engage
              third-party contractors or service providers. These parties may be
              involved in activities such as payment processing, ticket
              distribution, or event logistics. Outwire ensures that any
              third-party recipients of ticket data are authorized and trusted
              service providers who process data on behalf of Outwire, in
              accordance with this Privacy Policy and applicable legal
              requirements.
            </p>
            <p>
              <strong>f) User Consent</strong>: By engaging in ticket purchases
              or creating tickets within the Outwire application, users consent
              to the collection, processing, and retention of ticket-related
              data as described in this Privacy Policy. Users have the right to
              withdraw consent or exercise their rights regarding ticket data in
              accordance with the procedures outlined in Section 8 of this
              Privacy Policy.
            </p>
            <p>
              <strong>g) Contact</strong>: For any inquiries or concerns
              regarding the handling of ticket data or this Privacy Policy,
              users may contact Outwire via the provided contact information in
              Section 10. Outwire is committed to addressing user concerns and
              inquiries in a timely manner, in accordance with the applicable
              legislation.
            </p>
            <p>&nbsp;</p>
            <p>10. Contact</p>
            <p>You can exercise these rights at any time in one of two ways:</p>
            <p>
              By mail to the following address: Rua Helena de Arag&atilde;o, 2,
              1500-344 Lisboa;
            </p>
            <p>By e-mail to: support@outwire.app</p>
            <p>
              Outwire undertakes to respond to your requests concerning the
              Privacy Policy or the exercise of your rights, as soon as possible
              and no later than the time limit set forth by applicable
              legislation.
            </p>
            <p>&nbsp;</p>
          </Container>
        </Col>
        <Col sm={1}></Col>
      </Row>
    </Container>
  );
}
