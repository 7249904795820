import React, { useState, useRef } from "react";
import validator from "validator";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import picExemplo from "../homepage_phones.png";
import gp from "../googlePlay.png";
import appStore from "../appStore.png";

export default function SetionOutwire({ title }) {
  const [emailSent, setEmailSent] = useState(false);

  const [validated, setValidated] = useState(false);

  const UserForm = () => {
    const form = useRef(null);
    function submit(e) {
      if (form.current.checkValidity()) {
        const data = new FormData(form.current);
        fetch("https://management.outwire.app/api/addtomailinglist", {
          method: "POST",
          body: data,
        }).then((res) => {
          console.log(res);
          setEmailSent(res.ok);
        });
        return;
      } else {
        e.preventDefault();
        e.stopPropagation();
      }
      setValidated(true);
    }
    return (
      <Row className={"my-5 align-items-end"}>
        <Col sm={12} md={12} className={"py-2"}>
          <Row>
            <p className={"labelInput"}>
              We’re live and ready, find us on any app store!
            </p>
          </Row>
        </Col>
        <Col sm={6} md={4}>
          <Row>
          <a href={"https://play.google.com/store/apps/details?id=com.outwire.outwireapp.outwireapp&hl=pt-PT"} target={'_blank'}><img width={"100%"} src={gp} /></a>
            
          </Row>
        </Col>
        <Col sm={6} md={4}>
          <Row>
          <a href={"https://apps.apple.com/pt/app/outwire/id1659547503"} target={'_blank'}><img width={"100%"} src={appStore} /></a>
          </Row>
        </Col>
        <Col sm={12} md={4}>
          <Row>
            <div className={"buttonSize"}>
              {/* <Button
                onClick={submit}
                className={"w-100 buttonBg "}
                block
                size={"lg"}>
                Get Early Access
              </Button> */}
            </div>
          </Row>
        </Col>
      </Row>
    );
  };

  return (
    <Container className={"mb-5"}>
      <Row>
        <Col sm={1}></Col>
        <Col sm={10}>
          <Container>
            <Row className={"align-items-center"}>
              <Col sm={12} md={6}>
                <h1 className={"bigTitle"}>{title}</h1>
                {!emailSent ? (
                  <UserForm />
                ) : (
                  <p className={"my-5"}>
                    <b>Thank you for signing up our mailing list!</b>
                  </p>
                )}
              </Col>

              <Col sm={6} md={6}>
                <img width={"100%"} src={picExemplo} />
              </Col>
            </Row>
          </Container>
        </Col>
        <Col sm={1}></Col>
      </Row>
    </Container>
  );
}
