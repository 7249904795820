import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import "../App.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

export default function Terms() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <Container fluid className={"containerBg privacy"}>
      <Row className={"my-5 align-items-end"}>
        <Col sm={1}></Col>
        <Col sm={10} md={10}>
          <Container>
            <h3>Terms and Conditions</h3>
            <p>
              These terms and conditions apply to your use of the website of
              Outwire (<a href="http://www.outwire.app/">www.outwire.app</a>
              &nbsp;&ndash; hereafter the &ldquo;&nbsp;<strong>Website</strong>
              &rdquo;) and Outwire (mobile) application (iOS application /
              Android application &ndash; hereafter the &ldquo;
              <strong>App</strong>&rdquo;), provided by Outcity Lda., (hereafter
              "<strong>we</strong>" or "&nbsp;<strong>us</strong>" or &ldquo;
              <strong>Outwire</strong>&rdquo;).
            </p>
            <p>
              <strong>Definitions</strong>
            </p>
            <ol>
              <li>
                <strong>Terms</strong>: means these terms and conditions that
                apply to our Website, App, and all other services we provide.
              </li>
              <li>
                <strong>Privacy policy</strong>:<strong>&nbsp;</strong>means the
                privacy policy that applies to our Website, App, and all other
                services we provide, and which is available at&nbsp;
                <u>www.outwire.app/privacy</u>.
              </li>
            </ol>
            <p>
              <strong>Modifications</strong>
            </p>
            <ol>
              <li>
                We reserve the right to modify or replace these Terms and
                Conditions at any time if we find this reasonably necessary and
                useful. When Outwire changes these Terms and Conditions in a
                significant way, we will notify its members via e-mail or via a
                notification on the Website or App. By continuously using our
                Website or App you accept the changed Terms.
              </li>
            </ol>
            <p>
              <strong>Eligibility</strong>
            </p>
            <ol>
              <li>
                Outwire is a social network and event management platform, with
                the purpose to connect people through events, and allow their
                interaction inside the app.
              </li>
              <li>
                The Website and App are meant for adult members to communicate
                with each other online and is not suitable for individuals under
                18 years old. By using the Website or App, you confirm that you
                are at least 18 years old.
              </li>
              <li>
                If you have an unspent conviction, or are subject to any court
                order, relating to assault, violence, sexual misconduct or
                harassment you may not use the Website or App and we will be
                entitled to block and/or remove Your Account.
              </li>
              <li>
                If you are removed from our Website or App, you may not use our
                Website or App (again).
              </li>
              <li>
                When removed from our Website or App, your account will be
                permanently deleted.
              </li>
              <li>
                Users that post objectionable content on the Website or App will
                be removed from the Website and App.
              </li>
              <li>
                We may change, suspend, or discontinue any aspect of the service
                at any time, including hours of operation or availability of the
                service or any feature, without notice or liability.
              </li>
            </ol>
            <p>
              <strong>Registration</strong>
            </p>
            <ol>
              <li>
                If you want to fully use the Website or App, you first need to
                register. In order to register, we need your email address and
                password. After registering, you can create a personal account
                (&ldquo;Your Account&rdquo;). When creating Your Account, we
                will at least need your name, username and age. You are further
                invited to provide us with your personal interests, gender and
                profile picture.
              </li>
              <li>
                You agree to provide current, complete and accurate information
                when creating Your Account and when using the App and Website.
                You agree to keep Your Account updated.
              </li>
              <li>
                You will be required a password to access Your Account. You are
                solely responsible for the privacy of your password.
              </li>
              <li>
                Outwire is entitled to decline a registration, in case the
                information provided is insufficient or can compromise the
                safety of other users.
              </li>
              <li>
                May the user face difficulties in creating an account, or in the
                need for further information, they can contact&nbsp;
                <a href="mailto:support@outwire.app">support@outwire.app</a>.
              </li>
            </ol>
            <p>
              <strong>Privacy</strong>
            </p>
            <ol>
              <li>
                Several types of data will be processed when you use our Website
                or App. Insofar as personal data are concerned, Outwire will
                only process these data as laid down in the Privacy Statement.
              </li>
              <li>
                We also record non-personal data. Outwire may use this data to
                monitor the service, for analysis purposes and to improve the
                Website or App.
              </li>
            </ol>
            <p>
              <strong>Your information and content</strong>
            </p>
            <ol>
              <li>You are solely responsible for:</li>
            </ol>
            <ol>
              <ol>
                <li>
                  the information and content you post, transmit or publish on,
                  or share otherwise through the Website or App; and
                </li>
              </ol>
            </ol>
            <ol>
              <ol>
                <li>
                  your interactions with other members of the Website or App;
                </li>
              </ol>
            </ol>
            <ol>
              <li>
                Your Account is meant for personal use only. You may not
                authorize others to use your account, and you may not assign or
                otherwise transfer Your Account to any other person or entity.
                You are responsible for keeping your login details confidential
                and acknowledge that Outwire is not responsible for third party
                access to Your Account that results from sharing, theft or
                misappropriation of your login details. We strongly encourage
                you to choose a secure password that is hard to guess. Do not
                reveal your password to anyone. If you think your password has
                been compromised, please change it immediately via the
                &lsquo;Login&rsquo; page (&ldquo;forgot your password?&rdquo;).
              </li>
            </ol>
            <ol>
              <li>
                Your safety and personal integrity is important to us. Your
                (chat) messages are strictly confidential. We do use filter
                software that notifies us of (chat) messages with inappropriate
                content (e.g. offensive or aggressive language, or unlawful use
                of our platform for advertisements or link-building. Also see
                the enumeration under the heading &ldquo;Restrictions on
                information and content&rdquo; in the below). After such
                notification we reserve the right to erase the content in
                question.
              </li>
            </ol>
            <ol>
              <li>
                After being approved as a member of Outwire, we may monitor the
                information or content you share via the Website or App. We
                reserve the right to use filter software. In case we are
                notified by this software of inappropriate content, or in case
                we are notified of this by other members, we reserve the right
                to block the content in question.
              </li>
            </ol>
            <p>
              By submitting, providing, transmitting or otherwise sharing
              information and content via the Website or App, you grant us a
              non-exclusive, royalty-free, worldwide license of any rights,
              including intellectual property rights, on such information and
              content, to (re)publish and otherwise use any such content in any
              format on the Website, App, our social media platforms or in ads
              or other shielded areas that are only accessible by other members
              of Outwire.
            </p>
            <p>
              <strong>Restrictions on information and content</strong>
            </p>
            <ol>
              <li>
                You will not post, transmit, communicate or share information or
                content via the Website or App, that:
                <br /> a. is intended to or tends to harass, annoy, threaten or
                intimidate any other user of the Website or App;
                <br /> b. is defamatory, inaccurate, abusive, obscene, profane,
                offensive or sexually explicit;
                <br /> c. promotes racism, bigotry, hatred or physical harm of
                any kind against any group or individual;
                <br /> d. promotes or encourages illegal or unlawful activity or
                behaviour;
                <br /> e. contains chain letters, junk or spam email,
                advertising, promotional or commercial messages;
                <br /> f. contains an image of another person without permission
                of that person;
                <br /> g. contains an image of a person under 18 years of age;
                <br /> h. is misleading, false or impersonates any other person
                or entity.
                <br /> i. is intended to solicit another member's personal
                contact details or solicit communication with or on behalf of a
                person under 18 years; and
                <br /> j. which infringes any third party's (intellectual
                property) rights.
              </li>
              <li>
                You will immediately cease contacting any member who asks you to
                stop contacting them.
              </li>
              <li>
                You will not post, copy, modify, disclose or distribute via our
                Website or App:
              </li>
              <li>any confidential information; or</li>
              <li>
                any other material which is subject to our or a third party's
                (intellectual property) rights, e.g. a picture of you taken by a
                professional photographer, without first obtaining our or the
                relevant third party's prior written consent.
              </li>
              <li>
                You agree to compensate us for any claim or damages (including
                any legal fees in relation to such claim or damages) demanded by
                a third party in respect of any matter relating to or arising
                from any breach or suspected breach by you of these Terms or the
                rights of a third party.
                <br /> 2. We reserve the right to issue warnings, suspend access
                to Your Account or terminate Your Account, if we reasonably
                consider that you are in breach of these Terms.
              </li>
            </ol>
            <p>
              <strong>Risks and precautions</strong>
            </p>
            <ol>
              <li>&nbsp;</li>
              <ol>
                <li>
                  WE DO NOT CONDUCT (CRIMINAL) BACKGROUND CHECKS IN RELATION TO
                  MEMBERS OF OUTWIRE.
                </li>
                <li>
                  YOU ARE SOLELY RESPONSIBLE FOR TAKING ALL APPROPRIATE SAFETY
                  PRECAUTIONS IN CONNECTION WITH THE USE OF THE WEBSITE AND APP
                  AND CONTACTING OTHER MEMBERS. YOU ACCEPT THAT THERE ARE RISKS
                  INTERACTING ONLINE OR OFFLINE WITH OTHER MEMBERS, INCLUDING
                  DATING AND MEETING OTHER MEMBERS.
                </li>
                <li>
                  WE DO NOT GUARANTEE OR VERIFY THE ACCURACY OF INFORMATION
                  PROVIDED TO YOU BY OTHER MEMBERS. OUTWIRE MAKES EVERY EFFORT
                  TO KEEP THE INFORMATION MADE AVAILABLE ON THE WEBSITE AND APP
                  ACCURATE AND UP TO DATE, BUT WE DO NOT GUARANTEE THAT THE
                  INFORMATION IS ACCURATE, COMPLETE OR CURRENT. NO RIGHTS CAN BE
                  DERIVED FROM IT. ANY RELIANCE ON THE PROVIDED INFORMATION IS
                  AT YOUR OWN RISK.
                </li>
                <li>
                  WE DO NOT WARRANT THAT THE WEBSITE OR APP WILL BE AVAILABLE
                  UNINTERRUPTED AND IN A FULLY OPERATING CONDITION. ALL CONTENT
                  AND SERVICES ON THE WEBSITE OR APP ARE PROVIDED ON AN
                  &ldquo;AS IS&rdquo; AND &ldquo;AS AVAILABLE&rdquo; BASIS. ANY
                  DECISIONS OR ACTIONS TAKEN BY YOU ON THE BASIS OF THE
                  INFORMATION PROVIDED ON OR VIA THE WEBSITE AND APP ARE AT YOUR
                  SOLE DISCRETION AND RISK.
                </li>
              </ol>
            </ol>
            <p>
              <strong>Reporting disputes and other issues</strong>
            </p>
            <ol>
              <li>
                Please report any behaviour you encounter which is in breach of
                these Terms via&nbsp;<u>support@outwire.app</u>, including any
                behaviour which may be harmful, threatening, harassing or
                unlawful behaviour.
              </li>
              <li>
                If you wish to report another member, you can inform us by
                selecting the appropriate option available on the app.
              </li>
              <li>
                We are not obliged to become involved in any domestic or private
                disputes between members and do not provide any arbitration- or
                settlement-service.
              </li>
              <li>
                If you choose to invite someone to the Website or App through
                our &lsquo;Share Event&rsquo; feature, you may select a person
                directly from the contacts list on your device and send a text
                or email from your personal account. You understand and agree
                that you are responsible for any charges made against
                communications sent from your device. Because an invitation is
                coming directly from your personal account, social networks do
                not have access to or control over this communication.
              </li>
            </ol>
            <p>
              <strong>Third Parties</strong>
            </p>
            <ol>
              <li>&nbsp;</li>
              <ol>
                <li>
                  The Website and App may include links to third party websites
                  and applications. You are responsible for evaluating whether
                  you want to access or use them. We are not responsible for and
                  do not endorse any features, content, advertising, products,
                  or other materials on other websites or applications. You
                  assume all risk and we disclaim all liability arising from
                  your use of them.
                </li>
                <li>
                  Your correspondence or business deals with, or participation
                  in promotions of, advertisers or sweepstakes sponsors found on
                  or through the Website or App (including payment and delivery
                  of related goods or services, any personal information or
                  opt-in contact information voluntarily given to advertisers
                  and sweepstake sponsors, and any other terms, conditions,
                  warranties or representations associated with such dealings)
                  are solely between you and such advertiser.
                </li>
                <li>
                  You agree that Outwire will not in any way whatsoever be
                  responsible or liable for any claim, loss or damage of any
                  sort incurred, directly or indirectly, as the result of any
                  such dealings.
                </li>
              </ol>
            </ol>
            <p>
              <strong>Events</strong>
            </p>
            <ol>
              <li>
                Outwire may organize events for its members. We have the right
                to refuse members to events for any reason (for example in case
                of dress-codes etc). The events are on a first come and served
                base. We do not refund for no-shows, or late arrivals of special
                guests in relation to any event.
              </li>
              <li>
                We have the right to refuse or prohibit events that are
                organized by members via the Website or App and if organized by
                using Outwire trademark.
              </li>
              <li>
                We have the right to cancel ours or users&rsquo; events at any
                time, for variable reasons.
              </li>
              <li>
                Outwire events are not always for members only. To some events
                non-members may also attend.
              </li>
              <li>
                Photographs taken at events organized by us may be used for
                promotional purposes. This may include photographs taken of
                members that attend our events.
              </li>
              <li>
                Both Outwire and the venue/partner will not accept
                responsibility or liability whatsoever for any kind of injury,
                loss or damage suffered by any person to their property while
                present at an event, whatsoever or howsoever that injury, loss
                or damage is caused.
              </li>
            </ol>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <p>
              <strong>Ticketing Platform Terms and Conditions</strong>
            </p>
            <p>
              These additional terms and conditions (hereafter referred to as
              "Ticketing Terms") specifically apply to your use of the ticketing
              platform provided by Outwire (hereafter referred to as the
              "Ticketing Platform"). By accessing or using the Ticketing
              Platform, you agree to be bound by these Ticketing Terms in
              addition to the general Terms and Conditions outlined above.
              <br /> <br />{" "}
            </p>
            <ol>
              <li>
                <strong> Ticket Creation and Event Organization</strong>
              </li>
              <li>
                Event organizers ("Organizers") may create and publish events on
                the Ticketing Platform, subject to approval by Outwire. Outwire
                reserves the right to decline or remove any event that does not
                comply with the Ticketing Terms or violates applicable laws or
                regulations, or portrays abusive content.
              </li>
              <li>
                Organizers are solely responsible for the accuracy,
                completeness, and legality of the information provided when
                creating events and tickets on the Ticketing Platform, including
                event descriptions, dates, times, locations, ticket pricing, VAT
                number, minimum wage requirements and others.
              </li>
              <li>
                By creating an event on the Ticketing Platform, Organizers grant
                Outwire the right to display and promote the event on the
                Ticketing Platform and affiliated channels, including but not
                limited to the Outwire website and mobile applications.
              </li>
              <li>
                <strong> Ticket Pricing and Sales</strong>
              </li>
              <li>
                Organizers may set ticket prices and specify ticket types (e.g.,
                general admission, VIP, early bird) for their events on the
                Ticketing Platform.
              </li>
              <li>
                Organizers agree to comply with all applicable laws and
                regulations governing ticket pricing, sales, and distribution,
                including but not limited to consumer protection laws and
                regulations.
              </li>
              <li>
                Outwire may charge fees for ticket sales processed through the
                Ticketing Platform, which will be deducted from the ticket
                proceeds before remittance to the Organizer.
              </li>
              <li>
                <strong> Event Attendee Management</strong>
              </li>
              <li>
                Organizers are responsible for managing event attendees,
                including but not limited to verifying attendee identities,
                scanning tickets for admission, and enforcing event-specific
                terms and conditions.
              </li>
              <li>
                Organizers agree to abide by Outwire's policies regarding
                attendee privacy and data protection when collecting and
                processing attendee information.
              </li>
              <li>
                Organizers shall not discriminate against attendees on the basis
                of race, ethnicity, nationality, religion, gender, sexual
                orientation, age, disability, or any other protected
                characteristic.
              </li>
              <li>
                <strong> Event Cancellation and Refunds</strong>
              </li>
              <li>
                In the event of event cancellation or postponement, Organizers
                are responsible for communicating with ticket buyers and
                providing refunds or alternative arrangements as appropriate.
              </li>
              <li>
                Organizers shall indemnify and hold Outwire harmless from any
                claims, liabilities, or losses arising from event cancellations
                or changes initiated by the Organizer.
              </li>
            </ol>
            <p>&nbsp;</p>
            <ol start="5">
              <li>
                <strong> Ticketing Platform Usage</strong>
              </li>
              <li>
                The Ticketing Platform provided by Outwire enables users to
                purchase tickets for events organized by Outwire or other
                entities.
              </li>
              <li>
                Tickets purchased through the Ticketing Platform may be subject
                to additional terms and conditions specific to each event. By
                purchasing tickets, you agree to abide by these event-specific
                terms and conditions.
              </li>
              <li>
                The availability of tickets on the Ticketing Platform is subject
                to change without prior notice. Outwire reserves the right to
                modify, suspend, or discontinue the Ticketing Platform or any
                aspect thereof at any time without liability.
              </li>
              <li>
                <strong> Ticket Purchase and Delivery</strong>
              </li>
              <li>
                By purchasing tickets through the Ticketing Platform, you agree
                to provide accurate and complete information as required for the
                ticket purchase process.
              </li>
              <li>
                All ticket purchases made through the Ticketing Platform are
                final and non-refundable, unless otherwise specified in the
                event-specific terms and conditions.
              </li>
              <li>
                Upon successful completion of a ticket purchase, you will
                receive confirmation of your purchase via email or within the
                App.
              </li>
              <li>
                Outwire may offer various methods of ticket delivery, including
                electronic tickets (e-tickets) delivered within the App.
              </li>
              <li>
                <strong> Event Attendance and Admission</strong>
              </li>
              <li>
                Admission to events purchased through the Ticketing Platform is
                subject to the terms and conditions set forth by the event
                organizers. It is your responsibility to familiarize yourself
                with these terms and conditions prior to attending the event.
              </li>
              <li>
                Outwire and its affiliates reserve the right to refuse admission
                to any event for reasons including, but not limited to, failure
                to comply with event-specific terms and conditions, improper
                conduct, or violation of applicable laws or regulations.
              </li>
              <li>
                Outwire and its affiliates shall not be liable for any losses,
                damages, or expenses incurred because of denied admission to an
                event.
              </li>
              <li>
                <strong> Ticketing Platform Fees</strong>
              </li>
              <li>
                The Ticketing Platform may charge fees for ticket purchases,
                which will be clearly indicated at the time of purchase.
              </li>
              <li>
                All fees charged by the Ticketing Platform are non-refundable,
                unless otherwise specified.
              </li>
              <li>
                Outwire reserves the right to modify the fee structure for the
                Ticketing Platform at any time without prior notice.
              </li>
              <li>
                <strong> Dispute Resolution</strong>
              </li>
              <li>
                Any disputes arising from or relating to the Ticketing Platform
                or ticket purchases shall be resolved in accordance with the
                dispute resolution provisions outlined in the general Terms and
                Conditions.
              </li>
              <li>
                For inquiries, disputes, or assistance regarding ticket
                purchases made through the Ticketing Platform, please contact
                support@outwire.app.
              </li>
              <li>
                <strong> Limitation of Liability</strong>
              </li>
              <li>
                In addition to the limitation of liability provisions outlined
                in the general Terms and Conditions, Outwire and its affiliates
                shall not be liable for any losses, damages, or expenses arising
                from or relating to ticket purchases made through the Ticketing
                Platform, including but not limited to, event cancellations,
                postponements, or changes in venue or program.
              </li>
              <li>
                In no event shall Outwire's total liability for any claims
                arising from or relating to ticket purchases made through the
                Ticketing Platform exceed the total amount paid for the relevant
                ticket purchase.
              </li>
              <li>
                <strong> Governing Law</strong>
              </li>
              <li>
                These Ticketing Terms shall be governed by and construed in
                accordance with Portuguese law.
              </li>
              <li>
                Any disputes arising from or relating to these Ticketing Terms
                shall be subject to the exclusive jurisdiction of the courts of
                Portugal.
              </li>
            </ol>
            <p>
              By using the Ticketing Platform, you acknowledge that you have
              read, understood, and agree to abide by these Ticketing Terms. If
              you do not agree with these Ticketing Terms, you may not use the
              Ticketing Platform.
            </p>
            <p>&nbsp;</p>
            <p>
              <strong>Intellectual Property Rights</strong>
            </p>
            <ol>
              <li>
                Outwire is the exclusive owner of all intellectual property
                rights (such as patents, patent applications, trademarks,
                trademark applications, service marks, trade names, copyrights,
                trade secrets, licenses, domain names, mask works, information,
                proprietary rights, processes copyrights and trademarks) that
                vest in the design and content of the Website, App and the
                underlying software and database.
              </li>
              <li>
                The Website and App are intended solely for your personal,
                non-commercial use. Any other use of the Website and App
                requires prior written permission of Outwire.
              </li>
            </ol>
            <p>
              <strong>Limitation of liability</strong>
            </p>
            <ol>
              <li>
                IN NO EVENT WILL OUTWIRE AND ITS AFFILIATES, OFFICERS,
                EMPLOYEES, AGENTS, PARTNERS, AND LICENSORS BE LIABLE FOR ANY
                DAMAGES RELATING TO THE DECISION WHETHER OR NOT AN USER IS
                ALLOWED TO ACCESS OUTWIRE.
              </li>
              <li>
                We will provide Outwire&rsquo;s service to you with reasonable
                skill and care. We do not make any warranties or representations
                (neither express nor implied) with respect to the Website, App
                or the associated services.
              </li>
              <li>
                IN NO EVENT WILL OUTWIRE AND ITS AFFILIATES, OFFICERS,
                EMPLOYEES, AGENTS, PARTNERS AND LICENSORS BE LIABLE FOR ANY
                DAMAGES WHATSOEVER, WHETHER DIRECT OR INDIRECT, GENERAL,
                SPECIAL, COMPENSATORY, CONSEQUENTIAL, AND/OR INCIDENTAL, ARISING
                OUT OF, OR RELATING TO, THE CONDUCT OF YOU OR ANYONE ELSE IN
                CONNECTION WITH THE USE OF SERVICES, INCLUDING (BUT NOT LIMITED
                TO) BODILY INJURY, EMOTIONAL DISTRESS, LOSS OF BUSINESS, LOST
                PROFITS, TRADE SECRET MISAPPROPRIATION, INTELLECTUAL PROPERTY
                INFRINGEMENT, AND/OR ANY OTHER DAMAGES RESULTING FROM
                COMMUNICATIONS OR MEETINGS WITH OTHER MEMBERS OR PERSONS YOU
                MEET THROUGH OUR WEBSITE OR APP, OR RESULTING FROM ANY OTHER
                SERVICE WE PROVIDE.
              </li>
              <li>
                YOU HEREBY EXPRESSLY AGREE NOT TO HOLD OUTWIRE LIABLE FOR ANY
                INSTRUCTION, ADVICE, OR SERVICES DELIVERED THROUGH OUR WEBSITE
                OR APP. OUTWIRE EXPRESSLY DISCLAIMS ANY LIABILITY WHATSOEVER FOR
                ANY DAMAGE, SUITS, CLAIMS, AND/OR CONTROVERSIES THAT ARISE OR
                RELATE IN ANY WAY TO OUR WEBSITE OR APP.
              </li>
              <li>
                YOU EXPRESSLY UNDERSTAND AND AGREE THAT OUTWIRE WILL NOT BE
                LIABLE FOR DIRECT, INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL,
                OR EXEMPLARY DAMAGES, INCLUDING, BUT NOT LIMITED TO, DAMAGES FOR
                LOSS OF PROFITS, GOODWILL, USE, DATA OR OTHER INTANGIBLE LOSSES,
                RESULTING FROM:
                <br /> A) THE USE OR INABILITY TO USE THE WEBSITE OR APP;
                <br /> B) THE COST OF PROCUREMENT OF SUBSTITUTE GOODS AND
                SERVICES RESULTING FROM ANY GOODS, DATA, INFORMATION OR SERVICES
                OBTAINED OR MESSAGES RECEIVED OR TRANSACTIONS ENTERED INTO
                THROUGH, FROM, OR AS A RESULT OF THE WEBSITE OR APP;
                <br /> C) UNAUTHORIZED ACCESS TO OR ALTERATION OF YOUR
                TRANSMISSIONS OR DATA;
                <br /> D) STATEMENTS OR CONDUCT OF ANY USER OR THIRD PARTY ON
                THE WEBSITE OR APP;
                <br /> E) YOUR RELIANCE ON CONTENT DATA MADE AVAILABLE BY US; OR
                <br /> F) ANY OTHER MATTER RELATING TO THE WEBSITE OR APP.
              </li>
              <li>
                Nothing in these Terms shall exclude or limit our liability for
                our fraudulent misrepresentation or for death or personal injury
                resulting from our negligence or the negligence of our employees
                or agents.
              </li>
              <li>
                TO THE FULLEST EXTENT POSSIBLE BY LAW, OUTWIRE&rsquo;S MAXIMUM
                LIABILITY ARISING OUT OF OR IN CONNECTION WITH THE WEBSITE OR
                APP OR YOUR USE OF OUR CONTENT, REGARDLESS OF THE CAUSE OF
                ACTION (WHETHER IN CONTRACT, TORT, BREACH OF WARRANTY, OR
                OTHERWISE), WILL NOT EXCEED THE GREATER OF THE FEES YOU HAVE
                PAID TO OUTWIRE.
              </li>
            </ol>
            <p>
              <strong>Indemnity</strong>
            </p>
            <ol>
              <li>
                1. YOU AGREE TO DEFEND, INDEMNIFY, AND HOLD HARMLESS OUTWIRE
                FROM AND AGAINST ANY CLAIMS, ACTIONS, OR DEMANDS, INCLUDING
                WITHOUT LIMITATION REASONABLE LEGAL AND ACCOUNTING FEES,
                ALLEGING OR RESULTING FROM (A) YOUR USE OF OR RELIANCE ON ANY
                THIRD-PARTY CONTENT, (B) YOUR USE OF OR RELIANCE ON ANY CONTENT
                OF THE WEBSITE OR APP, (C) YOUR OWN CONTENT ON YOUR ACCOUNT, OR
                (D) YOUR BREACH OF THESE TERMS OR THE PRIVACY POLICY. WE WILL
                PROVIDE NOTICE TO YOU PROMPTLY OF ANY SUCH CLAIM, SUIT, OR
                PROCEEDING.
              </li>
            </ol>
            <p>
              <strong>Third Party Disputes</strong>
            </p>
            <ol>
              <li>
                1. Outwire is not affiliated with any service provider, or third
                party service, and any dispute you may have with any service
                provider, third party service or other third party, including,
                without limitation, any other user of the service, is directly
                between you and such third party, and you irrevocably release
                Outwire from any and all claims, demands and damages (actual and
                consequential) of every kind and nature, known and unknown,
                arising out of or in any way connected with such disputes.
              </li>
            </ol>
            <p>
              <strong>Other</strong>
            </p>
            <ol>
              <li>
                Outwire can be acquired for a part or in whole by another
                company. This will be done without (written) notice.
              </li>
              <li>
                If you breach these Terms and we take no action against you, we
                will still be entitled to use our rights and remedies in any
                other situation where you breach these Terms.
              </li>
              <li>
                If any part of these Terms is disallowed or found to be void by
                any court or regulator, the other provisions shall continue to
                apply. We will adjust these Terms as necessary, in which case
                the invalid terms will be replaced by valid terms that differ
                the least from the concerned invalid terms.
              </li>
              <li>
                These Terms are not intended to give rights to anyone except you
                and us.
              </li>
            </ol>
            <ol start="5">
              <li>These Terms are governed by Portuguese law.</li>
            </ol>
            <p>&nbsp;</p>
          </Container>
        </Col>
      </Row>
    </Container>
  );
}
